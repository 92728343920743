/* query */
export const LAZY_LIMIT = 999

/* api */
export const ASSETS = "https://assets.terra.money"
export const OBSERVER = "wss://observer.terra.dev"

/* website */
export const GEMINON = "https://geminon.fi"
export const TUTORIAL = "https://docs.geminon.fi"

/* media and sites */
export const MEDIUM = "https://geminonprotocol.medium.com/"
export const TWITTER = "https://twitter.com/Geminonprotocol"
export const GITHUB = "https://github.com/GeminonProtocol"
export const TELEGRAM_ANN = "https://t.me/geminon_ann"
export const TELEGRAM_GROUP = ""


/* ledger */
export const LEDGER_TRANSPORT_TIMEOUT = 120000

/* tx */
export const DEFAULT_GAS_ADJUSTMENT = 2

/* swap */
export const TERRASWAP_COMMISSION_RATE = 0.003

/* placeholder */
// https://github.com/terra-money/localterra
export const SAMPLE_ADDRESS = "terra1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v"
